

























































































import Vue from "vue";
import AutoCompleteSkillSearch from "@/components/shared/jobs/AutoCompleteSkillSearch.vue";
import { Skills } from "@/interfaces/data_objects/skills";
import { AddNewSkill } from "@/interfaces/shared/view_profile/add_new_skills";
import { extract_skill_group_styling } from "@/utils/skills_group";
import SkillsGroup from "@/components/shared/SkillsGroup.vue";
import { clean_string } from "@/utils/global";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  AUTH_LOADING,
  UPDATE_USER_PROFILE,
  USER_PROFILE
} from "@/store/modules/auth/constants";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";

export default Vue.extend({
  name: "AddNewSkill",
  components: { SkillsGroup, AutoCompleteSkillSearch },
  data(): AddNewSkill {
    return {
      selected_skills: [],
      active_skill_group: ""
    };
  },
  computed: {
    ...mapGetters("auth", {
      user_profile: USER_PROFILE,
      auth_loading: AUTH_LOADING
    })
  },
  methods: {
    ...mapActions("auth", {
      update_user_profile: UPDATE_USER_PROFILE
    }),
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    extract_skill_group_styling,
    searched_skill(skill: Skills) {
      if (!skill) return;
      const is_exist = this.selected_skills.findIndex(
        (val: Skills) => clean_string(val.name) === clean_string(skill.name)
      );
      if (is_exist === -1) this.selected_skills.push(skill);
    },
    update_skill_group(value: string) {
      if (this.active_skill_group === value) this.active_skill_group = "";
      else this.active_skill_group = value;
    },
    async update_profile() {
      const selected_skills = this.selected_skills.map((skill: Skills) => {
        return {
          ref_skill: skill.name,
          group: skill.group
        };
      });
      const payload = this.user_profile;
      payload.skills = payload.skills.concat(selected_skills);
      const response = await this.update_user_profile(payload);
      if (response) {
        this.root_notification("Profile Updated Successfully");
      } else this.root_error("Failed to update profile");
      this.cancel_adding();
    },
    cancel_adding() {
      this.active_skill_group = "";
      this.selected_skills = [];
      this.$emit("cancel", false);
    }
  }
});
