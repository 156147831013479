

























import Vue, { PropType } from "vue";
import { mapActions, mapGetters } from "vuex";
import { TranslateResult } from "vue-i18n";
import { AutoCompleteSkillSearch } from "@/interfaces/shared/jobs/auto_complete_skill_search";
import { COMMON_LOADING, SKILL_SEARCH } from "@/store/modules/common/constants";
import { capitalize_text } from "@/utils/global";
import { Skills } from "@/interfaces/data_objects/skills";

export default Vue.extend({
  name: "AutoCompleteSkillSearch",
  data(): AutoCompleteSkillSearch {
    return {
      selected_searched_skill: null,
      searched_skills: [],
      search_input: null
    };
  },
  computed: {
    ...mapGetters("common", {
      common_loading: COMMON_LOADING
    })
  },
  props: {
    hide_details: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    limit: {
      type: String,
      default: "39"
    },
    placeholder: {
      type: String as PropType<TranslateResult>
    },
    disabled: {
      type: Boolean,
      default: false
    },
    group_required: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    // Watcher for processing each string written in search field
    async search_input(value) {
      // If user press clear icon => reset job listing
      if (value === null) this.clear_autocomplete_search_value();
      // If user search value
      else if (value) {
        const obj = {
          skill_name: value.toLowerCase(),
          page: 0,
          limit_per_page: parseInt(this.limit)
        };
        const results = await this.skill_search(obj);
        if (results.length > 0) this.filter_searched_skills(results);
      }
    },
    // Watcher when user select job from autocomplete results
    selected_searched_skill(value) {
      if (value && this.group_required) this.$emit("searched_skill", value);
      else if (value && !this.group_required)
        this.$emit("searched_skill", value.name);
    }
  },
  methods: {
    ...mapActions("common", {
      skill_search: SKILL_SEARCH
    }),
    // Function to process searched skills to display in autocomplete dropdown
    filter_searched_skills(skills: Skills[]) {
      this.searched_skills = [];
      for (let skill of skills) {
        const obj: Skills = {
          name: capitalize_text(skill.name),
          group: capitalize_text(skill.group)
        };
        this.searched_skills.push(obj);
      }
    },
    // Function to reset state & pass null to parent
    clear_autocomplete_search_value() {
      this.reset_state();
      this.$emit("searched_skill", null);
    },
    // Reset state
    reset_state() {
      this.selected_searched_skill = null;
      this.searched_skills = [];
      this.search_input = null;
    }
  }
});
