















import Vue from "vue";

export default Vue.extend({
  name: "UpskillCareerLoader",
  data() {
    return {
      animation: require("@/assets/illustrations/analyzing.json")
    };
  },
  created() {
    this.animation = JSON.stringify(this.animation);
  }
});
